@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap&subset=latin-ext');

html, body {
  height: 100%;

  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

$gradient-start: #e0e0e0;
$gradient-end:   white;

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-image: linear-gradient(to right bottom, $gradient-start, $gradient-end);


  > main {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;

    .logo {
      max-width: 100%;
    }
  }

  > footer {
    height: 4rem;
  }
}

a:hover {
  text-decoration: none;
}

.spacer {
  font-size: 8rem;
  color: darken($gradient-start, 2.5%);
}

@import "contact";
@import "nav";
@import "background";
