nav {
  font-weight: bold;
  font-size: 1.5rem;

  ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;

    li {
      padding: .5rem 2rem;
      text-align: center;
      white-space: nowrap;

      a {
        display: block;
        color: black;
      }
    }
  }
}

@include media-breakpoint-down('sm') {
  nav ul {
    flex-wrap: wrap;

    li {
      width: 100%;
    }
  }
}
