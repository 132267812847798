.background {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  z-index: -1;

  canvas {
    width: 100%;
    height: 100%;

    opacity: 0.05;
  }

  .node {
    fill: black;
    z-index: -1;
  }

  .edge {
    stroke: black;
    //stroke-width: 1px;
    z-index: -2;
  }
}